import React, { useEffect,useState, useMemo} from 'react'
import {useQueryClient } from 'react-query'
import './table.scss'
import Pagination from '../pagination/Pagination'


const Table = props => {
    const {
      onPageChange,
      totalCount,
      currentPage,
      pageSize,
      head,
      data,
    } = props;

    const queryClient = useQueryClient()

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
          timeZone: 'America/Sao_Paulo',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        };
        return date.toLocaleDateString('pt-BR', options);
    };

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, data]);
    
    useEffect(() => {
        var lista = document.querySelectorAll("input");

        for ( var i = 0 ; i < lista.length ; i++ ){
            lista[i].checked = false
        }

       var updatedCheckedState = checkedState.map((intem, indice)=>{
            return ""
        })
        setCheckedState(updatedCheckedState)

    }, [currentPage]);

    const [checkedState, setCheckedState] = useState(new Array(20).fill("") );  
    const handleSelectAll = (status) => {

        let updatedCheckedState
        if(status){
            var lista = document.querySelectorAll("input");
    
            for ( var i = 0 ; i < lista.length ; i++ ){
                lista[i].checked = true
            }
            
            updatedCheckedState = currentTableData.map((intem, indice)=>{
                if(currentTableData[indice] !== undefined){
                    return currentTableData[indice]["_id"]
                }else{
                    return"i"
                }
            })
    
        }else{
            var lista = document.querySelectorAll("input");
    
            for ( var i = 0 ; i < lista.length ; i++ ){
                lista[i].checked = false
            }

            updatedCheckedState = checkedState.map((intem, indice)=>{
                return ""
            })
        }

        setCheckedState(updatedCheckedState);
        return updatedCheckedState
    }

    const handleChange = (position, status, value) => {
                
        var updatedCheckedState = checkedState.map((item, index) => 
        {
           if(index == position && status){
                return value
                
            }
            if(index == position && !status){
                return ''
                
            }
            return item
        });

        setCheckedState(updatedCheckedState);

        return updatedCheckedState
    };

    return (
        <div>
            <table className="taable">
                <thead className="head">
                    <tr className="th">
                        <th className="th">
                            <input         
                                className='box' 
                                type="checkbox"
                                onChange={(e) =>props.onChangeCheckBox(handleSelectAll(e.target.checked))}
                            />
                        </th>
                            {
                                head.map((key) => 
                                (<th className="th" key={key.value}>{key.label}</th>))
                            }
                    </tr>
                </thead>
                <tbody>
                    {currentTableData.length > 0 &&
                    currentTableData.map((row, indexx) => (
                        <tr key={indexx}>
                        <td className="td">
                            <input
                                className='box' 
                                type="checkbox"
                                id={`custom-checkbox-${indexx}`}
                                name={row['_id']}
                                value={row['_id']}
                                onChange={(e) => props.onChangeCheckBox(handleChange(indexx, e.target.checked, e.target.name))}
                            />
                        </td>
                            <td className="td">{row.nome || '-'}</td>
                            <td className="td">{formatDate(row.createdAt)}</td>
                            <td className="td">{row.empresa || '-'}</td>
                            <td className="td">{row.status_check || '-'}</td>
                            <td className="td">{row.operador || '-'}</td>
                            <td className="td">{formatDate(row.updatedAt)}</td>
                            <td className="td">{row.metodo || '-'}</td>
                            <td className="td">{parseFloat(row.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{justifyContent: 'center', display: 'flex'}}>
                <Pagination  onPageChange={onPageChange} totalCount={totalCount} siblingCount={1} currentPage={currentPage} pageSize={pageSize}/>
            </div>
        </div>
    )
}

export default Table

